import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Paper, Alert } from '@mui/material'; // Removed Box from here
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
  marginTop: '20px',
  marginBottom: '20px',
  padding: '20px',
});

const StyledPaper = styled(Paper)({
  padding: '20px',
  backgroundColor: '#F5F5F5',
});

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, { password });
      localStorage.setItem('token', response.data.token);
      onLogin();
    } catch (error) {
      setError('Invalid password');
    }
  };

  return (
    <StyledContainer maxWidth="sm">
      <StyledPaper>
        <Typography variant="h4" component="h1" gutterBottom>
          Login
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <TextField
            type="password"
            label="Password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </form>
      </StyledPaper>
    </StyledContainer>
  );
};

export default Login;
