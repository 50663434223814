import React, { useEffect, useState } from 'react';
import { useMediaQuery, Button } from '@mui/material';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Box,
  TableSortLabel,
  TextField,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import ViewListIcon from '@mui/icons-material/ViewList';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Login from './Login';

const StyledAppBar = styled(AppBar)({
  backgroundColor: '#2E3B55',
  boxShadow: 'none',
});

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between', // Changed to space-between to align logout button
});

const StyledContainer = styled(Container)({
  marginTop: '20px',
  marginBottom: '20px',
  padding: '20px',
});

const StyledPaper = styled(Paper)({
  padding: '20px',
  backgroundColor: '#F5F5F5',
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: '#2E3B55',
});

const StyledTableCellHead = styled(TableCell)({
  color: '#FFFFFF',
  fontWeight: 'bold',
  fontSize: '16px',
});

const StyledTableCell = styled(TableCell)({
  fontSize: '14px',
});

const Title = styled(Typography)({
  fontSize: '28px',
  fontWeight: 'bold',
  marginBottom: '20px',
});

const NameCell = styled(TableCell)({
  fontWeight: 'bold',
});


function App() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('payment_date');
  const [search, setSearch] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/unifiedpros`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
          .then(response => {
            setRecords(response.data);
            setLoading(false);
          })
          .catch(error => {
            if (error.response && error.response.status === 403) {
              localStorage.removeItem('token');
              setIsAuthenticated(false);
            } else {
              setError(error.message);
              setLoading(false);
            }
          });
    }
  }, [isAuthenticated]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  const filteredRecords = records.filter(record => {
    return (
        record.businessName.toLowerCase().includes(search.toLowerCase()) ||
        record.contactName.toLowerCase().includes(search.toLowerCase()) ||
        record.address.toLowerCase().includes(search.toLowerCase()) ||
        record.email.toLowerCase().includes(search.toLowerCase()) ||
        record.phone.toLowerCase().includes(search.toLowerCase()) ||
        record.website.toLowerCase().includes(search.toLowerCase())
    );
  });

  const sortedRecords = filteredRecords.sort((a, b) => {
    if (orderBy === 'payment_date' || orderBy === 'created' || orderBy === 'updated') {
      return order === 'asc'
          ? new Date(a[orderBy]) - new Date(b[orderBy])
          : new Date(b[orderBy]) - new Date(a[orderBy]);
    }
    return order === 'asc'
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
  });

  if (!isAuthenticated) {
    return <Login onLogin={() => setIsAuthenticated(true)} />;
  }

  if (loading) return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
  );
  if (error) return <Alert severity="error">Error: {error}</Alert>;

  return (
      <>
        <StyledAppBar position="static">
          <StyledToolbar>
            <Box display="flex" alignItems="center">
              <ViewListIcon style={{ marginRight: '16px', fontSize: isMobile ? '75px' : '64px' }} />
              <Typography variant="h4" component="div">
                Business Database Viewer
              </Typography>
            </Box>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </StyledToolbar>
        </StyledAppBar>
        <StyledContainer maxWidth="lg">
          <StyledPaper>
            <Box display="flex" alignItems="center" style={{ paddingLeft: '5px' }}>
              <AssignmentIcon style={{ marginRight: '10px', marginBottom: '16px', fontSize: '34px' }} />
              <Title variant="h4" component="h1">
                Business Records
              </Title>
            </Box>
            <TextField
                variant="outlined"
                placeholder="Search..."
                fullWidth
                margin="normal"
                value={search}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                  ),
                }}
            />
            <TableContainer component={Paper}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCellHead>Business Name</StyledTableCellHead>
                    <StyledTableCellHead>Contact Name</StyledTableCellHead>
                    <StyledTableCellHead>Address</StyledTableCellHead>
                    <StyledTableCellHead>Phone</StyledTableCellHead>
                    <StyledTableCellHead>Email</StyledTableCellHead>
                    <StyledTableCellHead>Website</StyledTableCellHead>
                    <StyledTableCellHead>Paid</StyledTableCellHead>
                    <StyledTableCellHead>
                      <TableSortLabel
                          active={orderBy === 'payment_date'}
                          direction={orderBy === 'payment_date' ? order : 'asc'}
                          onClick={() => handleRequestSort('payment_date')}
                      >
                        Payment Date
                      </TableSortLabel>
                    </StyledTableCellHead>
                    <StyledTableCellHead>Message</StyledTableCellHead>
                    <StyledTableCellHead>
                      <TableSortLabel
                          active={orderBy === 'created'}
                          direction={orderBy === 'created' ? order : 'asc'}
                          onClick={() => handleRequestSort('created')}
                      >
                        Created
                      </TableSortLabel>
                    </StyledTableCellHead>
                    <StyledTableCellHead>
                      <TableSortLabel
                          active={orderBy === 'updated'}
                          direction={orderBy === 'updated' ? order : 'asc'}
                          onClick={() => handleRequestSort('updated')}
                      >
                        Updated
                      </TableSortLabel>
                    </StyledTableCellHead>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {sortedRecords.map(record => (
                      <TableRow key={record.id}>
                        <NameCell>{record.businessName}</NameCell>
                        <StyledTableCell>{record.contactName}</StyledTableCell>
                        <StyledTableCell>{record.address}</StyledTableCell>
                        <StyledTableCell>{record.phone}</StyledTableCell>
                        <StyledTableCell>{record.email}</StyledTableCell>
                        <StyledTableCell>{record.website}</StyledTableCell>
                        <StyledTableCell>{record.paid ? 'Yes' : 'No'}</StyledTableCell>
                        <StyledTableCell>{new Date(record.payment_date).toLocaleString()}</StyledTableCell>
                        <StyledTableCell>{record.message}</StyledTableCell>
                        <StyledTableCell>{new Date(record.created).toLocaleString()}</StyledTableCell>
                        <StyledTableCell>{new Date(record.updated).toLocaleString()}</StyledTableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </StyledContainer>
      </>
  );
}

export default App;